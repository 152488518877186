<template>
  <div>
    <div class="form-group">
      <b>Denumire procedură</b>
      <input
        type="text"
        class="vInput"
        v-model="editedPaapCentralization.name"
      />
    </div>
    <div class="form-group">
      <b>CPV - Denumire</b>
      <input
        type="text"
        class="vInput form-disabled"
        :disabled="true"
        :value="
          `${editedPaapCentralization.cpvCode} - ${editedPaapCentralization.cpvCodeName}`
        "
      />
    </div>

    <div class="form-group">
      <div style="display: flex; gap: 5px;">
        <div style="flex: 1;">
          <b>Suma totală</b>
          <input
            type="text"
            class="vInput form-disabled"
            :disabled="true"
            :value="
              toPriceFormat(editedPaapCentralization.totalValueWithoutTva)
            "
          />
        </div>
        <div style="flex: 1;">
          <b>Procedura recomandată</b>
          <input
            type="text"
            class="vInput form-disabled"
            :disabled="true"
            :value="
              editedPaapCentralization &&
              editedPaapCentralization.recomendedProcedureType
                ? editedPaapCentralization.recomendedProcedureType.name
                : null
            "
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <b
        >Procedura stabilită/instrumente specifice pentru derularea procesului
        de achiziție</b
      >
      <v-select
        v-model="editedPaapCentralization.procedureType"
        label="name"
        :options="procedures"
      >
        <template #option="item">
          <span class="v-select_li_custom">
            <span>{{ item["name"] }}</span>
            <i v-tooltip="item['name']" class="fas fa-info-circle"></i>
          </span>
        </template>
      </v-select>
    </div>

    <div class="form-group">
      <b>Rezultatul procedurii</b>
      <v-select
        v-model="editedPaapCentralization.procedureResult"
        :options="[
          'Acord-cadru',
          'Acord-cadru/Contract subsecvent',
          'Angajament legal',
          'Contract subsecvent',
          'Contract',
          'Comandă fermă',
        ]"
      >
      </v-select>
    </div>

    <div class="form-group">
      <div style="display: flex; gap: 5px;">
        <div style="flex: 1;">
          <b>Data estimată inițiere procedură</b>
          <date-picker
            v-model="editedPaapCentralization.estimateInitProcedureDate"
            valueType="date"
            popup-class="bigger-index"
            format="DD-MM-YYYY"
            :disabled-date="estimateInitProcedureDateDisabledDate"
          />
        </div>
        <div style="flex: 1;">
          <b>Data estimată atribuire</b>
          <date-picker
            v-model="editedPaapCentralization.estimateDateForSignContract"
            valueType="date"
            popup-class="bigger-index"
            format="DD-MM-YYYY"
            :disabled-date="estimateDateForSignContractDisabledDate"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <b>Sursa de finanțare</b>
      <v-select
        v-model="editedPaapCentralization.financingSource"
        label="sourceName"
        class="form-disabled"
        :disabled="true"
      >
        <template #option="item">
          <span class="v-select_li_custom">
            <span>{{ item["sourceName"] }}</span>
            <i v-tooltip="item['sourceName']" class="fas fa-info-circle"></i>
          </span>
        </template>
      </v-select>
    </div>

    <div class="form-group">
      <b>Modalitatea de derulare a procedurii de atribuire</b>
      <v-select
        v-model="editedPaapCentralization.modalityToProcessProcedure"
        :options="['online', 'offline']"
      >
      </v-select>
    </div>

    <div class="form-group">
      <b>Persoana responsabilă cu aplicarea procedurii de atribuire</b>
      <v-select
        v-model="editedPaapCentralization.responsablePersonRole"
        label="label"
        :options="responsablePerson"
      >
        <template #option="item">
          <span class="v-select_li_custom">
            <span>{{ item["label"] }}</span>
            <i v-tooltip="item['label']" class="fas fa-info-circle"></i>
          </span>
        </template>
      </v-select>
    </div>

    <div class="form-group">
      <b
        >Data introducerii procedurii în Programul anual al achizițiilor
        publice</b
      >
      <date-picker
        v-model="editedPaapCentralization.dateForLastProcedureAdded"
        valueType="date"
        popup-class="bigger-index"
        format="DD-MM-YYYY"
        disabled
      />
    </div>
  </div>
</template>

<script>
import DatePicker from "@/mixins/DatePicker.js";

import { USERS_INSTITUTION, PROCEDURE_TYPE_WITH_REGISTER } from "../api.js";
export default {
  name: "EditPaapCentralization",
  props: {
    initial: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [DatePicker],

  data() {
    return {
      editedPaapCentralization: {
        ...this.initial,
        estimateInitProcedureDate: this.initial.estimateInitProcedureDate
          ? new Date(this.initial.estimateInitProcedureDate)
          : "",
        estimateDateForSignContract: this.initial.estimateDateForSignContract
          ? new Date(this.initial.estimateDateForSignContract)
          : "",
        dateForLastProcedureAdded: this.initial.dateForLastProcedureAdded
          ? new Date(this.initial.dateForLastProcedureAdded)
          : "",
      },
      procedures: [],
      responsablePerson: [],
    };
  },
  watch: {
    editedPaapCentralization: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    estimateInitProcedureDateDisabledDate(date) {
      const currentDate = new Date();

      const maxDatePickerDate = new Date(
        this.editedPaapCentralization.estimateDateForSignContract
      );
      if (currentDate > date || maxDatePickerDate < date) {
        return true;
      }
      return false;
    },
    estimateDateForSignContractDisabledDate(date) {
      const currentDate = new Date();
      const minDatePickerDate = new Date();

      const maxDatePickerDate = new Date();
      maxDatePickerDate.setFullYear(currentDate.getFullYear());
      maxDatePickerDate.setMonth(11);
      maxDatePickerDate.setDate(31);

      if (minDatePickerDate > date || maxDatePickerDate < date) {
        return true;
      }
      return false;
    },
  },

  created() {
    PROCEDURE_TYPE_WITH_REGISTER(`paapCentralizationId=${this.initial.id}`)
      .then((res) => {
        this.procedures = res.data;
      })
      .catch((err) => {});

    USERS_INSTITUTION()
      .then((res) => {
        if (Array.isArray(res?.data?.result)) {
          this.responsablePerson = res.data.result
            .flatMap(
              (e) =>
                e?.userRoles?.map((i) => {
                  if (i && i.role == "responsabil_achizitii") {
                    return {
                      ...i,
                      id: i.id ?? this.makeid(6),
                      _userId: e.id,
                      label:
                        this.readUserObject(e) +
                        (i.department?.name ? ` — ${i.department?.name}` : "") +
                        (i.role ? ` — ${this.changeRoleView(i.role)}` : ""),
                    };
                  }
                  return null;
                }) || []
            )
            .filter((el) => el)
            .sort((a, b) => a.label - b.label);
        } else {
        }
      })
      .catch();
  },
};
</script>

<style></style>
